
export const environment: any = {
    production: true,
    apiUrl: 'https://api.abonnementmag.fr',
    frontUrl: 'https://www.abonnementmag.fr',
    stripe : {
        publicKey: 'pk_live_pFc4U2feRxT7lBxQkKQ3XVEk'
    },
    apiTokenLogin: {
        username: 'burda',
        password: 'NyzHusHr8A8YTWUx',
    },
    googleReCaptchaKey: '6Lf-aq0kAAAAAMOXQCn2l7dTc6YswKFOPYwCSv11',
    sentry: 'https://7d7ef7c8c8804d62b17dd7f2b337c1fb@o4504735496667136.ingest.sentry.io/4504853683044352',
    googleAddressKey: 'AIzaSyAlGRYY4RPmclJTRxMeNh60HXhoUrfQ-CQ',
    immanensPv5api: 'https://pressview5.immanens.com/api'
};
